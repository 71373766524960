import { forEach } from "lodash"
import React, { useState, useContext, createContext } from "react"
import { flushSync } from "react-dom"

const Context = createContext({
    open: false,
    openModal: () => {},
    closeModal: () => {},
    shipmentId: undefined,
    setShipmentId: () => {},
    shouldOpen: false,
    setShouldOpen: () => {},
    generateOrdersToBookList: [],
    ordersToBook: new Map(),
    setOrdersToBook: () => {},
    isBooking: false,
    setIsBooking: () => {},
})

export const useBookedShipmentContext = () => {
    const bookedShipmentContext = useContext(Context)
    if (!bookedShipmentContext) {
        throw new Error(
            "Cannot use booked shipment context ouside of bookedShipmentProvider"
        )
    }
    return bookedShipmentContext
}

const BookedShipmentProviderContext = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [shipmentId, setShipmentId] = useState(undefined)
    const [shouldOpen, setShouldOpen] = useState(false)
    const [ordersToBook, setOrdersToBook] = useState(new Map())
    const [isBooking, setIsBooking] = useState(false)

    const openModal = shipmentId => {
        setShipmentId(shipmentId)
        setOpen(true)
        setShouldOpen(false)
    }

    const closeModal = () => {
        setShipmentId(undefined)
        setOpen(false)
    }

    const generateOrdersToBookList = (order, selectedRate) => {
        if (
            ordersToBook.has(order.id) &&
            JSON.stringify(ordersToBook.get(order.id)) ===
                JSON.stringify({ ...order, selectedRate: selectedRate })
        ) {
            ordersToBook.delete(order.id)
            setOrdersToBook(new Map(ordersToBook))
        } else {
            setOrdersToBook(
                new Map(ordersToBook).set(order.id, {
                    ...order,
                    selectedRate: selectedRate,
                })
            )
        }
    }

    const generateMultiOrdersToBookList = bookList => {
        let newOrdersToBook = new Map(ordersToBook)
        for (let booking of bookList) {
            newOrdersToBook.set(booking.order._id, {
                ...booking.order,
                selectedRate: booking.rate,
            })
        }
        setOrdersToBook(newOrdersToBook)
    }

    return (
        <Context.Provider
            value={{
                open,
                openModal,
                ordersToBook,
                closeModal,
                shipmentId,
                setShipmentId,
                shouldOpen,
                setOrdersToBook,
                setShouldOpen,
                generateOrdersToBookList,
                generateMultiOrdersToBookList,
                isBooking,
                setIsBooking,
            }}
        >
            {children}
        </Context.Provider>
    )
}

export const BookedShipmentProvider = BookedShipmentProviderContext
